import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import ClientSlider from "./ClientSlider";

export default function TrustedBy() {
  return (
    <Container className="trustedBy">
      <Card>
        <Card.Body>
          <Row className="align-items-center">
            <Col xs={12} md={3} className="px-4">
              <h4>Preferred by Institutions Globally</h4>
            </Col>
            <Col xs={12} md={9}>
              <ClientSlider/>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
}
