import React from "react";
import { Image, Button, Col, Container, Row } from "react-bootstrap";
import MainHeading from "./MainHeading";
import student from "../images/student.jpg";
import Header from "./PageHeader";
import ProfessionalsRecommending from "./ProfessionalsRecommending";
const sectionsData = [
  {
    title: "Schools",
    description:
      "This system provides administrators with an intuitive and cost-effective way to manage their preschool facility's operations, saving significant time in the process.",
    image: student,
  },
  {
    title: "K-12 Schools",
    description:
      "We offer comprehensive school management services for K-12 institutions, including budgeting, finance management, scheduling, and attendance tracking. Our expert solutions provide a one-stop service for all your school management needs.",
    image: student,
  },
  {
    title: "High School Solution",
    description:
      "Our comprehensive solution brings together students, operations, and data on a single platform. By integrating various systems, our innovative platform streamlines operations for higher education institutions, simplifying the academic management process.",
    image: student,
  },
  {
    title: "College Management Solutions",
    description:
      "The JadeedEMS Educational ERP software is fully equipped to handle college administration tasks. With its comprehensive features, it allows for detailed evaluations of student performance, attendance, funding, and other critical data.",
    image: student,
  },
  {
    title: "University Management Solution",
    description:
      "Implementing a cloud-based university management system provides a comprehensive ERP solution that optimizes various university operations and automates tasks.",
    image: student,
  },
  {
    title: "Vocational Schools",
    description:
      "An all-in-one software solution for efficient management of vocational schools, offering essential features for education and training. It enables the creation of classroom-based courses, assignment of students to lessons, and effective weekly management of teachers' schedules.",
    image: student,
  },
  // Add more sections as needed
];

export default function Solutions() {
  return (
    <div className="solutions">
      <Header
        title="Solutions "
        caption="Tailored Solutions for Every Educational Institution"
      />

      {sectionsData.map((section, index) => (
        <section key={index}>
          <Container>
            <Row xs={1} md={2} className={`align-items-center text-start`}>
              {/* Conditionally swap the order of columns based on index */}
              {index % 2 === 0 ? (
                <>
                  <Col>
                    <MainHeading
                      title={section.title}
                      description={section.description}
                    />
                    <Button variant="warning">Read More</Button>
                  </Col>
                  <Col className="text-center">
                    <Image src={student} alt={section.title} fluid />
                  </Col>
                </>
              ) : (
                <>
                  <Col className="text-center">
                    <Image src={student} alt={section.title} fluid />
                  </Col>
                  <Col>
                    <MainHeading
                      title={section.title}
                      description={section.description}
                    />
                    <Button variant="warning">Read More</Button>
                  </Col>
                </>
              )}
            </Row>
          </Container>
        </section>
      ))}
      <ProfessionalsRecommending/>
    </div>
  );
}
