import React, { useState, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { NavDropdown, Nav, Navbar, Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

const NavigationBar = () => {
  const [scroll, setScroll] = useState(false);
  const location = useLocation(); // Hook to access the current location

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScroll(true);
      } else {
        setScroll(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    // Scroll to top on route change
    window.scrollTo(0, 0);
  }, [location]); // Dependency on location will trigger effect on route change

  const Solutions = [
    { name: "University Management Solution", link: "/University" },
    { name: "College Management Solutions", link: "/College" },
    { name: "High School Solution", link: "/Highschool" },
    { name: "K-12 Schools", link: "/K12Schools" },
    { name: "Preschool Institute", link: "/Preschool" },
    { name: "Vocational Schools", link: "/Vocational" },
  ];

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      fixed="top"
      className={scroll ? "navbar-scroll" : ""}
    >
      <Container>
        <Navbar.Brand as={Link} to="/"></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link as={Link} to="/">
              Home
            </Nav.Link>

            <NavDropdown title="Features" id="features-dropdown">
              {Solutions.map((feature, index) => (
                <NavDropdown.Item as={Link} to={feature.link} key={index}>
                  {feature.name}
                </NavDropdown.Item>
              ))}
            </NavDropdown>

            <Nav.Link as={Link} to="/Pricing">
              Pricing
            </Nav.Link>
            <NavDropdown title="Solutions" id="solutions-dropdown">
              {Solutions.map((feature, index) => (
                <NavDropdown.Item as={Link} to={feature.link} key={index}>
                  {feature.name}
                </NavDropdown.Item>
              ))}
            </NavDropdown>
            {/* Mega Menu for Solutions */}
            <Nav.Link as={Link} to="/Demo">
              Demo
            </Nav.Link>
            <Nav.Link as={Link} to="/Contact">
              Contact Us
            </Nav.Link>
            <Nav.Link className="btn btn-warning" as={Link} to="/Trail">
              Free Trial
            </Nav.Link>
          </Nav>

          <Nav>
            <NavDropdown
              title={<FontAwesomeIcon icon={faUser} />}
              id="userlogin"
              align="end"
              className="user-login no-caret"
            >
              <NavDropdown.Item as={Link} to="/Login">
                Login
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavigationBar;
