import React from 'react'
import { Link } from 'react-router-dom';
import {Col, Container, Image, Row } from "react-bootstrap";
import MainHeading from "./MainHeading";
import ceoimage from"../images/CEOJEMS.svg";
import sales from"../images/sales.png";
import SvgIcon from './SvgIcon';
export default function HomeAbout() {
  return (
    <>
    <section>
      <Container>
        <Row xs={2} md={2} lg={2} xl={2} className="align-items-center justify-content-center">
            <Col className="about-image mb-3" data-aos="fade-right">
              <Image src={ceoimage} alt='' fluid />
              <div className='sales'>
                <h6>sales-Comparison</h6>
                <Image src={sales} alt='' fluid />
              </div>
              <div className='faculty'>
                <Row className='align-items-center'>
                  <Col xs={1} md={4}><SvgIcon icon="faculty" /></Col>
                  <Col xs={1} md={8}>
                    <h6>85٪</h6>
                    <p>Elevate Faculty Efficiency</p>
                  </Col>
                </Row>
              </div>
              <div className='fee'>
                <Row className='align-items-center'>
                  <Col xs={1} md={4}><SvgIcon icon="fee" /></Col>
                  <Col xs={1} md={8}>
                    <h6>120٪</h6>
                    <p>Enhance Fee Collection</p>
                  </Col>
                </Row>
                
              </div>
            </Col>
            <Col className="about-content"data-aos="fade-left">
              <MainHeading
                slogan="About JEMS"
                title="Welcome to Jadeed Education Management System"
                description="Jadeed Education Management System (EMS) is a cutting-edge, comprehensive platform designed to streamline and enhance the management of educational institutions. Our software is tailored to meet the diverse needs of schools, colleges, and universities, providing an all-in-one solution for administrative, academic, and communication tasks."
              />
              <h6 className='text-warning'>Vision</h6>
              <p>Leading in education management, driving innovation and excellence.</p>
              <h6 className='text-warning'>Mission</h6>
              <p>Empowering education through technology—accessible, efficient, and engaging.</p>
              <Link to="/About" className="btn btn-warning">
                Read More
              </Link>
            </Col>
        </Row>
      </Container>
    </section>
    </>
  )
}
