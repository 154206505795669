import React from 'react'
import Header from '../PageHeader'
import {Image, Button, Col, Container, Row } from 'react-bootstrap'
import MainHeading from '../MainHeading'
import Card from "../Card";
import teacher from"../../images/teacher.jpg";
import ProfessionalsRecommending from '../ProfessionalsRecommending';
const FacultyFeatures = [
  {
    title: "Faculty Profiles",
    sicon: "university",
    description:"Maintain detailed, up-to-date records of faculty, including contact info, qualifications, and experience.",
  },
  {
    title: "Attendance Management",
    sicon: "university",
    description:"Streamline administration by efficiently tracking attendance, managing absences, and automating notifications.",
  },
  {
    title: "Schedule Management",
    sicon: "university",
    description:"Optimize class and meeting schedules with advanced tools for efficient time and resource management.",
  },
  {
    title: "Course Management",
    sicon: "university",
    description:"Streamline course assignments, track progress, and manage allocations for effective course management.",
  },
  {
    title: "Performance Evaluation",
    sicon: "university",
    description:"Track faculty performance with peer reviews and student feedback to generate insights for improvement.",
  },
  {
    title: "Leave / Absence Tracking",
    sicon: "university",
    description:"Simplify leave management by tracking absences, patterns, and updating leave balances automatically.",
  },
  {
    title: "Payroll Management",
    sicon: "university",
    description:"Manage salary, bonuses, and deductions, and generate detailed pay-slips for clear payment information.",
  },
  {
    title: "Professional Development",
    sicon: "university",
    description:"Organize training, track certifications, and document development to support ongoing faculty growth.",
  },
  {
    title: "Document Management",
    sicon: "university",
    description:"Facilitate uploading and storing important documents with robust access controls to ensure data security and privacy.",
  },
  {
    title: "Report Generation",
    sicon: "university",
    description:"Generate reports on attendance, performance, and metrics, with customization, and export as PDF or Excel.",
  },
  {
    title: "Integration with Academic",
    sicon: "university",
    description:"Sync with student systems to integrate courses, timetables, exams, and results for seamless information flow.",
  },
  {
    title: "Data Analytics",
    sicon: "university",
    description:"Leverage analytics with visual dashboards to identify performance trends and effectively track key metrics.",
  },
  {
    title: "Mobile Access",
    sicon: "university",
    description:"Access the system easily from smartphones and tablets through a mobile interface or app for on-the-go convenience.",
  },
  {
    title: "Role-Based Access Control",
    sicon: "university",
    description:"Implement secure, customizable access levels for administrators, faculty, and users to ensure data protection and control.",
  },
  {
    title: "Notifications and Alerts",
    sicon: "university",
    description:"Set reminders for deadlines and meetings, and use automated alerts for critical issues to stay proactive in managing tasks.",
  },
  {
    title: "Customizable Workflows",
    sicon: "university",
    description:"Tailor workflows to fit specific processes and automate routine tasks to boost efficiency and streamline operations.",
  },
]
export default function Faculty() {
  return (
    <>
    <Header title="Faculty Management System" caption="Streamline and Enhance Faculty Operations with Our Management System"/>
    <section>
        <Container>
            <Row xs={1} md={2} className='align-items-center'>
                <Col>
                    <MainHeading title="Faculty Management System" description="A versatile Faculty Management System, optimized for mobile devices, simplifies every aspect of the faculty lifecycle—from onboarding and recruitment to workload management and performance tracking—providing valuable insights for institutions."/>
                    <Button variant="warning">Try  for free</Button>{' '}<Button variant="secondary">Get a quote</Button>{' '}
                </Col>
                <Col className='text-center'><Image src={teacher} alt='' fluid /></Col>
            </Row>
        </Container>
    </section>
    <section>
        <Container>
          <Row className='text-center'>
            <Col>
            <MainHeading slogan="Features" title="Faculty Management Software - Tailored for Your Faculty Needs" description="Enter a Realm Where Technology and Pedagogy Converge, Crafting an Ideal Ecosystem for Inspiring Educators."/>
            </Col>
          </Row>
          <Row sm={1} md={3} lg={4} xl={4} className="align-items-center justify-content-center text-center" >
            {FacultyFeatures.map((card, index) => (
              <Col key={index} className="mb-3">
                <Card sicon={card.sicon} title={card.title} alt={card.title} description={card.description}/>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    <ProfessionalsRecommending/>
    </>
  )
}
