import React from "react";
import { Accordion, Button } from "react-bootstrap";
const accordionData = [
  {
    eventKey: "0",
    header: "Innovative and User-Friendly",
    body: "Jadeed EMS blends cutting-edge technology with an intuitive interface for a seamless experience for educators, students, and administrators.",
  },
  {
    eventKey: "1",
    header: "Customizable and Scalable",
    body: "Jadeed EMS is highly customizable to fit your unique needs and scalable to accommodate institutions of all sizes, from small schools to large universities.",
    
},
{
    eventKey: "2",
    header: "Cost-Effective Solution",
    body: "Jadeed EMS provides a cost-effective solution with competitive pricing, delivering exceptional value and enhancing efficiency to save you time and money.",
  },
  {
    eventKey: "3",
    header: "Mobile APP",
    body: "Jadeed EMS includes a mobile app that offers convenient access to features like attendance tracking, grade management, and communication tools, ensuring seamless management on the go.",
  },
  
];
export default function WhyChoose() {
  return (
    <Accordion>
      {accordionData.map((item) => (
        <Accordion.Item eventKey={item.eventKey} key={item.eventKey}>
          <Accordion.Header>{item.header}</Accordion.Header>
          <Accordion.Body>
            {item.body}
            {item.showButton && (
              <div className="mt-2"><Button variant="primary">{item.buttonText}</Button></div>
            )}
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
}
