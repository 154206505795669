import React from "react";
import Header from "./PageHeader";
import { Card, CardBody, Col, Container, Row } from "react-bootstrap";
import ContactForm from "./ContactForm";
import MainHeading from "./MainHeading";

export default function Contact() {
  return (
    <>
      <Header
        title="Trail"
        caption="Get a sneak peek of our JEMS Education Management System and discover how it can revolutionize your educational experience!"
      />
      
      
    </>
  );
}
