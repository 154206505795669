import React from 'react'
import Header from '../PageHeader'
import {Image, Button, Col, Container, Row } from 'react-bootstrap'
import MainHeading from '../MainHeading'
import Card from "../Card";
import student from"../../images/student.jpg";
import ProfessionalsRecommending from '../ProfessionalsRecommending';
const StudentFeatures = [
  {
    title: "Faculty",
    sicon: "university",
    description:"Maintain",
  },
]
export default function Admission() {
  return (
    <>
    <Header title="Student Information System" caption="Empowering education with seamless student management"/>
    <section>
        <Container>
            <Row xs={1} md={2} className='align-items-center'>
                <Col>
                    <MainHeading title="Student Information System" description="Consolidate and manage all student-related information in one place, from personal details and attendance records to disciplinary actions, achievements, and more. Discover the advantages of our Student Information System (SIS) today."/>
                    <Button variant="warning">Try  for free</Button>{' '}<Button variant="secondary">Get a quote</Button>{' '}
                </Col>
                <Col className='text-center'><Image src={student} alt='' fluid /></Col>
            </Row>
        </Container>
    </section>
    <section>
        <Container>
          <Row className='text-center'>
            <Col>
            <MainHeading slogan="Features" title="Faculty Management Software - Tailored for Your Faculty Needs" description="Enter a Realm Where Technology and Pedagogy Converge, Crafting an Ideal Ecosystem for Inspiring Educators."/>
            </Col>
          </Row>
          <Row sm={1} md={3} lg={4} xl={4} className="align-items-center justify-content-center text-center" >
            {StudentFeatures.map((card, index) => (
              <Col key={index} className="mb-3">
                <Card sicon={card.sicon} title={card.title} alt={card.title} description={card.description}/>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    <ProfessionalsRecommending/>
    </>
  )
}
