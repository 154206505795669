import React from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SvgIcon from './SvgIcon';

export default function Card({ title, subtitle, sicon, svgicone, ficon, icon, name, alt, description, link, linkText, linkSvgIcon, items }) {
  return (
    <div className="card ">
      <div className="card-body">
        {ficon && <div className="card-icon"><FontAwesomeIcon icon={ficon} aria-label={alt}/></div>}
        {sicon && <div className="card-icon"><SvgIcon icon={sicon} aria-label={alt} /></div>}
        {svgicone && <div className="card-svgicone"><SvgIcon icon={svgicone} aria-label={alt} /></div>}
        {icon && <div className="card-icon"><img src={icon} alt={name} /></div>}
        {title && <div className="card-title">{title}</div>}
        {subtitle && <div className="sub-title">{subtitle}</div>}
        {description && <div className="card-description">{description}</div>}

        {/* List of items */}
        {items && items.length > 0 && (
          <ul className="card-list">
            {items.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        )}

        {link && linkText && (
          <NavLink to={link} className="card-link">
            {linkText}
            {linkSvgIcon && <SvgIcon icon={linkSvgIcon} className="link-icon" />}
          </NavLink>
        )}
      </div>
    </div>
  );
}

