import React from "react";
import { useNavigate } from 'react-router-dom';
import Card from "./Card";
import MainHeading from "./MainHeading";
import { Button, Col, Container, Row } from "react-bootstrap";
import { faUserGraduate, faUserTie, faChartLine, faBook, faUserEdit, faPen, faComputer, faFileAlt, faUserGroup, faPlus, faCalendarDays, faListCheck, faPeopleRoof } from "@fortawesome/free-solid-svg-icons";

const cardsData = [
  {
    title: "Student",
    ficon: faUserGraduate,
    caption: "Management System",
    alt: "User Graduate Icon",
    linktext: "Read More",
    link: "/Student",
    linkSvgIcon: "nextarrow",
  },
  {
    title: "Faculty",
    caption: "Management System",
    ficon: faUserTie,
    alt: "User Tie Icon",
    linktext: "Read More",
    link: "/Faculty",
    linkSvgIcon: "nextarrow",
  },
  {
    title: "Course",
    caption: "Management System",
    ficon: faBook,
    alt: "Book Icon",
    linktext: "Read More",
    link: "/Course",
    linkSvgIcon: "nextarrow",
  },
  {
    title: "Financial",
    caption: "Management System",
    ficon: faChartLine,
    alt: "Chart Line Icon",
    linktext: "Read More",
    link: "/Financial",
    linkSvgIcon: "nextarrow",
  },
  {
    title: "Admission",
    caption: "Management System",
    ficon: faUserEdit,
    alt: "User Edit Icon",
    linktext: "Read More",
    link: "/Admission",
    linkSvgIcon: "nextarrow",
  },
  {
    title: "Exams",
    caption: "Management System",
    ficon: faPen,
    alt: "Pen Icon",
    linktext: "Read More",
    link: "/Exams",
    linkSvgIcon: "nextarrow",
  },
  {
    title: "LMS",
    caption: "Management System",
    ficon: faComputer,
    alt: "Computer Icon",
    linktext: "Read More",
    link: "/LMS",
    linkSvgIcon: "nextarrow",
  },
  {
    title: "Gradebook",
    caption: "Management System",
    ficon: faFileAlt,
    alt: "File Alt Icon",
    linktext: "Read More",
    link: "/Gradebook",
    linkSvgIcon: "nextarrow",
  },
  {
    title: "Attendance",
    caption: "Management System",
    ficon: faCalendarDays,
    alt: "Arrow Up Icon",
    linktext: "Read More",
    link: "/Attendance",
    linkSvgIcon: "nextarrow",
  },
  {
    title: "Assignments",
    caption: "Management System",
    ficon: faListCheck,
    alt: "Arrow Up Icon",
    linktext: "Read More",
    link: "/Assignments",
    linkSvgIcon: "nextarrow",
  },
  {
    title: "Parent Login",
    caption: "Management System",
    ficon: faPeopleRoof,
    alt: "User Group Icon",
    linktext: "Read More",
    link: "/ParentLogin",
    linkSvgIcon: "nextarrow",
  },
  {
    title: "60+ More",
    caption: "Management System",
    ficon: faPlus,
    alt: "Plus Icon",
    linktext: "View All",
    link: "/Features",
    linkSvgIcon: "nextarrow",
    
  },
  // Add more cards as needed
];
export default function HomeFeatures() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/features');
  };
  return (
    <>
    <section className="features">
      <Container>
        <Row className="align-items-center justify-content-center text-center" >
          <Col md={10} lg={10} xl={10} className="text-center">
            <MainHeading
              slogan="Features"
              title="Comprehensive Education Management System Modules"
              description="Streamline education with our versatile modules. Simplify administration, boost collaboration, and support student success from enrollment to assessment with intuitive, integrated tools."
            />
          </Col>
        </Row>
        <Row xs={2} md={2} lg={4} xl={4} className="justify-content-center text-center">
          {cardsData.map((card, index) => (
            <Col key={index} className="mb-3">
              <Card 
        ficon={card.ficon} 
        title={card.title} 
        description={card.caption} 
        alt={card.alt} 
        link={card.link} 
        linkText={card.linktext} 
        linkSvgIcon={card.linkSvgIcon}
      />
            </Col>
          ))}
          
        </Row>
        <Row className="justify-content-center text-center">
          <Col>
          <Button variant="btn btn-warning" onClick={handleClick}>View More</Button>{" "}
          </Col>
        </Row>
      </Container>
    </section>
    </>
  );
}
