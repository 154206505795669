import React from 'react'
import Header from '../PageHeader'
import {Image, Button, Col, Container, Row } from 'react-bootstrap'
import MainHeading from '../MainHeading'
import Card from "../Card";
import student from"../../images/student.jpg";
import ProfessionalsRecommending from '../ProfessionalsRecommending';
const StudentAdvantages = [
  {
    title: "Administrators",
    description:"Enjoy a seamless admissions process with our user-friendly system, making enrollment simple and stress-free. Join with ease and assurance.",
    cardItems: ["Simplified admission process.", "Efficient day-to-day operations.", "Centralized data storage facility.", "Advanced data security measures.",]
  },
  {
    title: "Teachers",
    description:"Our streamlined process guarantees smooth admissions, enhancing user-friendliness and instilling confidence in the enrollment experience",
    cardItems: ["Communication with both students and parents.", "Reports on student progress based on data analysis.", "A centralized platform for managing student grades.", "Handle a student's records.",]
  },
  {
    title: "Students",
    description:"Enhance your educational journey with efficient performance tracking, schedule access, and real-time event alerts, all designed to enrich your experience.",
    cardItems: ["Efficient communication with educators.","Assess and monitor their performance and progress.","Access to attendance, timetable, examination schedules, and more.","Immediate alerts for crucial events."]
  },
  {
    title: "Parents",
    description:"Stay updated with progress tracking, effortless fee payments, and enhanced teacher engagement in creative programs, all contributing to your child's well-rounded development.",
    cardItems: ["Monitor and trace students' progress.", "Convenient fee payment process.", "Improved communication with instructors.","Increased participation in creative programs.",]
  },
]
const StudentFeatures = [
  {
    title: "Student Data Management",
    sicon: "university",
    description:"Store and organize comprehensive student data, including personal information, academic performance, documents, and attendance records.",
  },
  {
    title: "Admission Management",
    sicon: "university",
    description:"Automate admissions to minimize errors, manage student and parent data, customize forms, and streamline document processing.",
  },
  {
    title: "Attendance Management",
    sicon: "university",
    description:"Automate attendance tracking with a mobile app to record attendance by subject or day and send instant alerts to parents for missed classes.",
  },
  {
    title: "Examination Management",
    sicon: "university",
    description:"Automate the entire examination process, including exam scheduling, notifications, and sharing results with students and parents.",
  },
  {
    title: "Transport Management",
    sicon: "university",
    description:"Manage transportation fees, ensuring student safety and secure, timely transport. Coordinate logistics and resolve issues to maintain a safe system.",
  },
  {
    title: "Mobile App",
    sicon: "university",
    description:"Access features anytime, anywhere: view your timetable, mark attendance, check results, see events, track fees, and interact with others.",
  },
]
export default function Student() {
  return (
    <>
    <Header title="Student Information System" caption="Empowering education with seamless student management"/>
    <section>
        <Container>
            <Row xs={1} md={2} className='align-items-center'>
                <Col>
                    <MainHeading title="Student Information System" description="Consolidate and manage all student-related information in one place, from personal details and attendance records to disciplinary actions, achievements, and more. Discover the advantages of our Student Information System (SIS) today."/>
                    <Button variant="warning">Try  for free</Button>{' '}<Button variant="secondary">Get a quote</Button>{' '}
                </Col>
                <Col className='text-center'><Image src={student} alt='' fluid /></Col>
            </Row>
            <Row className='text-center justify-content-center'>
            <Col md={10}>
            <MainHeading slogan="Advantages" title="What Are The Benefits Of The Student Management System?" description="The Student Management System provides numerous advantages for educational institutions. It streamlines administrative tasks, boosts student engagement, and supports data-driven decision-making, making it a transformative tool in modern education."/>
            </Col>
          </Row>
          <Row sm={1} md={2} lg={2} xl={2} className="align-items-center justify-content-center text-center" >
            {StudentAdvantages.map((card, index) => (
              <Col key={index} className="mb-3 text-start">
                <Card sicon={card.sicon} title={card.title} alt={card.title} description={card.description} items={card.cardItems}/>
              </Col>
            ))}
          </Row>
        </Container>
    </section>
      <ProfessionalsRecommending/>
      <section>
        <Container>
          <Row className='text-center justify-content-center'>
            <Col md={10}>
            <MainHeading slogan="Features" title="Student Information Management System Capabilities" description="Explore the robust capabilities of our Student Information Management System from streamlined data handling to insightful analytics, discover how it transforms education administration."/>
            </Col>
          </Row>
          <Row sm={1} md={3} lg={4} xl={4} className="align-items-center justify-content-center text-center" >
            {StudentFeatures.map((card, index) => (
              <Col key={index} className="mb-3">
                <Card sicon={card.sicon} title={card.title} alt={card.title} description={card.description}/>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </>
  )
}
