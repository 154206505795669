import React from "react";
import MainHeading from "./MainHeading";
import { Col, Container, Row } from "react-bootstrap";
import Card from "./Card";
import { faUserGraduate } from "@fortawesome/free-solid-svg-icons";
import Header from "./PageHeader";
import headerbg from "../bg.jpg";
import ProfessionalsRecommending from "./ProfessionalsRecommending";
const coremodules = [
  {
    title: "Admission",
    ficon: faUserGraduate,
    alt: "Admission",
    description:"Create a simple, clear registration process for quick and easy enrollment.",
    linktext: "Read More",
    link: "/Admission",
    linkSvgIcon: "nextarrow",
  },
  {
    title: "Course",
    ficon: faUserGraduate,
    alt: "Course",
    description: "Easily manage courses, subjects, and sessions for your educational institute with just a click.",
      linktext: "Read More",
    link: "/Course",
    linkSvgIcon: "nextarrow",
  },
  {
    title: "Exams",
    ficon: faUserGraduate,
    alt: "User Graduate Icon",
    description:
      "Streamline your exams with JadeedEMS! Easily schedule using CCE, CPA, and more in just a few clicks.",
      linktext: "Read More",
    link: "/Exams",
    linkSvgIcon: "nextarrow",
  },
  {
    title: "Faculty",
    ficon: faUserGraduate,
    alt: "User Graduate Icon",
    description:
      "A complete faculty system that automates everything from recruitment to workload management.",
      linktext: "Read More",
    link: "/Faculty",
    linkSvgIcon: "nextarrow",
  },
  {
    title: "Financial",
    ficon: faUserGraduate,
    alt: "Financial",
    description:
      "Stay in control with automated reminders, tracking, and seamless reconciliation.",
      linktext: "Read More",
    link: "/Financial",
    linkSvgIcon: "nextarrow",
  },
  {
    title: "Student",
    ficon: faUserGraduate,
    alt: "User Graduate Icon",
    description:
      "Centralize student information for easy access and informed decision-making.",
    linktext: "Read More",
    link: "/Student",
    linkSvgIcon: "nextarrow",
  },
];
const essentialmodule = [
  {
    title: "Application",
    ficon: faUserGraduate,
    alt: "User Graduate Icon",
    description:
      "Streamline admissions with our all-in-one solution for efficient application collection and processing.",
      linktext: "Read More",
    link: "/Student",
    linkSvgIcon: "nextarrow",
  },
  {
    title: "Assignment",
    ficon: faUserGraduate,
    alt: "User Graduate Icon",
    description:
      "Assign and efficiently manage tasks for individual students, groups, or entire batches with just a few clicks.",
      linktext: "Read More",
    link: "/Student",
    linkSvgIcon: "nextarrow",
  },
  {
    title: "Attendance",
    ficon: faUserGraduate,
    alt: "User Graduate Icon",
    description:
      "Eliminate manual errors and effortlessly track attendance accurately across multiple courses and classes.",
      linktext: "Read More",
    link: "/Student",
    linkSvgIcon: "nextarrow",
  },
  {
    title: "Class Room",
    ficon: faUserGraduate,
    alt: "User Graduate Icon",
    description:
      "JadeedEMS streamlines classroom management. Enhance efficiency and excel with our solution.",
      linktext: "Read More",
      link: "/Student",
      linkSvgIcon: "nextarrow",
    },
  {
    title: "Gradebook",
    ficon: faUserGraduate,
    alt: "User Graduate Icon",
    description:
      "Manage student grades and monitor online progress from a single, centralized Gradebook.",
      linktext: "Read More",
    link: "/Student",
    linkSvgIcon: "nextarrow",
  },
  {
    title: "Payments",
    ficon: faUserGraduate,
    alt: "User Graduate Icon",
    description:
      "Our integrated financial suite handles student fees and course enrollments quickly and effortlessly.",
      linktext: "Read More",
    link: "/Student",
    linkSvgIcon: "nextarrow",
  },
  {
    title: "Time Table",
    ficon: faUserGraduate,
    alt: "User Graduate Icon",
    description:
      "Effortlessly schedule sessions, manage courses, and notify students and faculty with a few clicks.",
      linktext: "Read More",
    link: "/Student",
    linkSvgIcon: "nextarrow",
  },
  {
    title: "Time Sheet",
    ficon: faUserGraduate,
    alt: "User Graduate Icon",
    description:
      "A powerful timesheet tool for tracking time, attendance, productivity, and other key metrics.",
      linktext: "Read More",
    link: "/Student",
    linkSvgIcon: "nextarrow",
  },
];
const advancemodule = [
  {
    title: "Advance Accounting",
    ficon: faUserGraduate,
    alt: "User Graduate Icon",
    description:
      "Fast, precise, and cost-effective cloud accounting software for educational institutions.",
      linktext: "Read More",
    link: "/Student",
    linkSvgIcon: "nextarrow",
  },
  {
    title: "Biometric ",
    ficon: faUserGraduate,
    alt: "User Graduate Icon",
    description:
      "Fast and accurate cloud-based accounting software, designed for schools and educational institutions.",
      linktext: "Read More",
    link: "/Student",
    linkSvgIcon: "nextarrow",
  },
  {
    title: "Dashboard",
    ficon: faUserGraduate,
    alt: "User Graduate Icon",
    description:
      "View all KPIs in one place, customize dashboards and reports, and make data-driven decisions.",
      linktext: "Read More",
    link: "/Student",
    linkSvgIcon: "nextarrow",
  },
  {
    title: "Documents",
    ficon: faUserGraduate,
    alt: "User Graduate Icon",
    description:
      "Streamline document management and compliance with a single, intuitive platform.",
      linktext: "Read More",
    link: "/Student",
    linkSvgIcon: "nextarrow",
  },
  {
    title: "Events",
    ficon: faUserGraduate,
    alt: "User Graduate Icon",
    description:
      "Easily organize events, sell tickets, track attendees, and communicate with them throughout the event.",
      linktext: "Read More",
    link: "/Student",
    linkSvgIcon: "nextarrow",
  },
  {
    title: "KPI Dashboard",
    ficon: faUserGraduate,
    alt: "User Graduate Icon",
    description:
      "Monitor all departments with a real-time dashboard for a comprehensive view of your business’s health.",
      linktext: "Read More",
    link: "/Student",
    linkSvgIcon: "nextarrow",
  },
  {
    title: "library ",
    ficon: faUserGraduate,
    alt: "User Graduate Icon",
    description:
      "With this library management software, effortlessly manage books, articles, media, and more in a centralized system.",
      linktext: "Read More",
    link: "/Student",
    linkSvgIcon: "nextarrow",
  },
  {
    title: "Mobile Application",
    ficon: faUserGraduate,
    alt: "User Graduate Icon",
    description:
      "Provide students, parents, and faculty with quick and easy access to class details, assignments, and events.",
      linktext: "Read More",
    link: "/Student",
    linkSvgIcon: "nextarrow",
  },
  {
    title: "Multi Approvals",
    ficon: faUserGraduate,
    alt: "User Graduate Icon",
    description:
      "JadeedEMS Multi Approval centralizes requests and manages different approval types from one place.",
      linktext: "Read More",
    link: "/Student",
    linkSvgIcon: "nextarrow",
  },
  {
    title: "Reporting",
    ficon: faUserGraduate,
    alt: "User Graduate Icon",
    description:
      "Easily analyze data, gain insights, and generate reports on your organization's departments and activities.",
      linktext: "Read More",
    link: "/Student",
    linkSvgIcon: "nextarrow",
  },
];
const erpmodule = [
  {
    title: "Accounting",
    ficon: faUserGraduate,
    alt: "User Graduate Icon",
    description:
      "Manage financial tasks like invoicing, receipts, payments, and overdue balances with a unified system.",
      linktext: "Read More",
    link: "/Student",
    linkSvgIcon: "nextarrow",
  },
  {
    title: "Appraisals",
    ficon: faUserGraduate,
    alt: "User Graduate Icon",
    description:
      "Enhance evaluations to gain insights and improve workforce development through regular assessments.",
      linktext: "Read More",
    link: "/Student",
    linkSvgIcon: "nextarrow",
  },
  {
    title: "CRM",
    ficon: faUserGraduate,
    alt: "User Graduate Icon",
    description:
      "Automate lead and opportunity tracking with a cutting-edge CRM system for a competitive edge.",
      linktext: "Read More",
    link: "/Student",
    linkSvgIcon: "nextarrow",
  },
  {
    title: "payroll",
    ficon: faUserGraduate,
    alt: "payroll",
    description:
      "Effortlessly manages payroll for all employees with batch payslip creation and daily attendance tracking.",
      linktext: "Read More",
    link: "/Student",
    linkSvgIcon: "nextarrow",
  },
  {
    title: "expenses ",
    ficon: faUserGraduate,
    alt: "User Graduate Icon",
    description:
      "Educational institutions can streamline expenses, reduce errors, optimize budgets, and improve financial reporting.",
      linktext: "Read More",
    link: "/Student",
    linkSvgIcon: "nextarrow",
  },
  {
    title: "Documents",
    ficon: faUserGraduate,
    alt: "User Graduate Icon",
    description:
      "Allow faculty and students to manage school documents and check compliance on a single, intuitive platform.",
      linktext: "Read More",
    link: "/Student",
    linkSvgIcon: "nextarrow",
  },
];
const managementmodule = [
  {
    title: "campus ",
    ficon: faUserGraduate,
    alt: "User Graduate Icon",
    description:
      "An intuitive system for efficient booking, managing, and transacting with campus amenities and entities.",
      linktext: "Read More",
    link: "/Student",
    linkSvgIcon: "nextarrow",
  },
  {
    title: "parent Login ",
    ficon: faUserGraduate,
    alt: "User Graduate Icon",
    description:
      "Enhance transparency for parents with a dedicated login to view their child's academic activities and achievements.",
      linktext: "Read More",
    link: "/Student",
    linkSvgIcon: "nextarrow",
  },
  {
    title: "transportation ",
    ficon: faUserGraduate,
    alt: "User Graduate Icon",
    description:
      "Ensure the safety, efficiency, and effectiveness of transportation facilities within educational institutions.",
      linktext: "Read More",
    link: "/Student",
    linkSvgIcon: "nextarrow",
  },
];
const technicalmodule = [
  {
    title: "customization ",
    ficon: faUserGraduate,
    alt: "User Graduate Icon",
    description:
      "Adapt and tailor the system to fit your organizational workflow and align with your processes.",
      linktext: "Read More",
    link: "/Student",
    linkSvgIcon: "nextarrow",
  },
  {
    title: "Data import / export",
    ficon: faUserGraduate,
    alt: "User Graduate Icon",
    description:
      "Seamlessly import or export existing data in widely accepted formats such as PDF and XLS.",
      linktext: "Read More",
    link: "/Student",
    linkSvgIcon: "nextarrow",
  },
  {
    title: "Web Based ",
    ficon: faUserGraduate,
    alt: "User Graduate Icon",
    description:
      "Can be deployed either on the cloud or on-premises, based on the organization's preferences and requirements.",
      linktext: "Read More",
    link: "/Student",
    linkSvgIcon: "nextarrow",
  },
  {
    title: "modular ",
    ficon: faUserGraduate,
    alt: "User Graduate Icon",
    description:
      "A flexible, modular system that allows for the seamless addition of new features without requiring downtime.",
      linktext: "Read More",
    link: "/Student",
    linkSvgIcon: "nextarrow",
  },
  {
    title: "multiple Organization",
    ficon: faUserGraduate,
    alt: "User Graduate Icon",
    description:
      "Manage multiple campuses and branches from one system, with data segregation for each location.",
      linktext: "Read More",
    link: "/Student",
    linkSvgIcon: "nextarrow",
  },
  {
    title: "On Cloud On Premise",
    ficon: faUserGraduate,
    alt: "User Graduate Icon",
    description:
      "Select the optimal deployment strategy for your system, whether on the cloud or on local servers at your premises.",
      linktext: "Read More",
    link: "/Student",
    linkSvgIcon: "nextarrow",
  },
  {
    title: "secure",
    ficon: faUserGraduate,
    alt: "User Graduate Icon",
    description:
      "Equipped with advanced granular data access controls to protect your data from unauthorized access.",
      linktext: "Read More",
    link: "/Student",
    linkSvgIcon: "nextarrow",
  },
];
export default function Featurs() {
  return (
    <>
    
    <div className="featurs">
    <Header backgroundImage={headerbg} title="Features " caption="Streamline Learning, Empower Educators, Engage Students: Revolutionize Your Classroom with Our All-in-One EMS" />
      <section>
        <Container>
          <Row className="align-items-center justify-content-center text-center" >
            <Col sm={1} md={10} lg={8} xl={8}>
            <MainHeading title="Core Module" description="Centralize your academic operations with our Core Module – streamline student management, course scheduling, and faculty coordination for a seamless educational experience."/>
            </Col>
          </Row>
          <Row sm={1} md={3} lg={4} xl={4} className="align-items-center justify-content-center text-center" >
            {coremodules.map((card, index) => (
              <Col key={index} className="mb-3">
                <Card ficon={card.ficon} title={card.title} alt={card.alt} description={card.description} link={card.link} linkText={card.linktext} linkSvgIcon={card.linkSvgIcon}/>
              </Col>
            ))}
          </Row>
          <Row className="align-items-center justify-content-center text-center" >
          <Col sm={1} md={10} lg={8} xl={8}>
            <MainHeading title="Essential Module" description="Unlock the foundation of efficient school management with our Essential Module – simplify attendance tracking, grading, and communication to ensure smooth day-to-day operations."/>
            </Col>
          </Row>
          <Row sm={1} md={3} lg={4} xl={4} className="align-items-center justify-content-center text-center" >
            {essentialmodule.map((card, index) => (
              <Col key={index} className="mb-3">
                <Card ficon={card.ficon} title={card.title} alt={card.alt} description={card.description} link={card.link} linkText={card.linktext} linkSvgIcon={card.linkSvgIcon}/>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
      <section>
        <Container>
        <Row className="align-items-center justify-content-center text-center" >
          <Col sm={1} md={10} lg={8} xl={8}>
            <MainHeading title="Advance Module" description="Stay ahead with our Advance Module – leverage advanced analytics, AI-driven insights, and automated reporting to enhance decision-making and drive institutional growth."/>
            </Col>
          </Row>
          <Row sm={1} md={3} lg={4} xl={4} className="align-items-center justify-content-center text-center" >
            {advancemodule.map((card, index) => (
              <Col key={index} className="mb-3">
                <Card ficon={card.ficon} title={card.title} alt={card.alt} description={card.description} link={card.link} linkText={card.linktext} linkSvgIcon={card.linkSvgIcon}/>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
      <section>
        <Container>
        <Row className="align-items-center justify-content-center text-center" >
          <Col sm={1} md={10} lg={8} xl={8}>
            <MainHeading title="ERP Module" description="Transform your educational operations with our ERP Module – integrate finance, HR, procurement, and academic workflows into a unified system for seamless, data-driven management."/>
            </Col>
          </Row>
          <Row sm={1} md={3} lg={4} xl={4} className="align-items-center justify-content-center text-center" >
            {erpmodule.map((card, index) => (
              <Col key={index} className="mb-3">
                <Card ficon={card.ficon} title={card.title} alt={card.alt} description={card.description} link={card.link} linkText={card.linktext} linkSvgIcon={card.linkSvgIcon}/>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
      <section>
        <Container>
        <Row className="align-items-center justify-content-center text-center" >
          <Col sm={1} md={10} lg={8} xl={8}>
            <MainHeading title="Management Module"description="Optimize administrative efficiency with our Management Module – streamline resource allocation, staff management, and decision-making processes for effective leadership and control."/>
            </Col>
          </Row>
          <Row sm={1} md={3} lg={4} xl={4} className="align-items-center justify-content-center text-center" >
            {managementmodule.map((card, index) => (
              <Col key={index} className="mb-3">
                <Card ficon={card.ficon} title={card.title} alt={card.alt} description={card.description} link={card.link} linkText={card.linktext} linkSvgIcon={card.linkSvgIcon}/>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
      <section>
        <Container>
        <Row className="align-items-center justify-content-center text-center" >
          <Col sm={1} md={10} lg={8} xl={8}>
            <MainHeading title="Technical Module" description="Empower your institution with our Technical Module – manage IT infrastructure, system security, and software integrations to maintain a robust and secure digital environment."/>
            </Col>
          </Row>
          <Row sm={1} md={3} lg={4} xl={4} className="align-items-center justify-content-center text-center" >
            {technicalmodule.map((card, index) => (
              <Col key={index} className="mb-3">
                <Card ficon={card.ficon} title={card.title} alt={card.alt} description={card.description} link={card.link} linkText={card.linktext} linkSvgIcon={card.linkSvgIcon}/>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </div>
    <ProfessionalsRecommending/>
    </>
  );
}
