import React from 'react'
import { Link } from 'react-router-dom';
import Header from './PageHeader'
export default function NoPage() {
  return (
    <>
    <Header title="404" caption="Page Not Found"/>
    <div className="no-page">
      <Link to="/">Go Back to Home</Link>
    </div>
    </>
    
  )
}
