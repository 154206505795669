import { useEffect } from 'react';
import "./App.css";
import "./Hover.css";
import AOS from 'aos';
import 'aos/dist/aos.css';

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import About from "./components/About";
import Contact from "./components/Contact";
import Demo from "./components/Demo";
import NoPage from "./components/NoPage";
import Navbar from "./components/NavigationBar";
import Pricing from "./components/Pricing";
import Login from "./components/Login";
import Trail from "./components/Trail";
// 
import Features from "./components/Features";

import Student from "./components/features/Student";
import Faculty from "./components/features/Faculty";
import Course from "./components/features/Course";
import Financial from "./components/features/Financial";
import Admission from "./components/features/Admission";
import Exams from "./components/features/Exams";
import LMS from "./components/features/LMS";
import Gradebook from "./components/features/Gradebook";
import Attendance from "./components/features/Attendance";
import Assignments from "./components/features/Assignments";
import ParentLogin from "./components/features/ParentLogin";

// 
import Solutions from "./components/Solutions";

import College from "./components/solutions/College";
import Highschool from "./components/solutions/Highschool";
import K12Schools from "./components/solutions/K12Schools";
import Preschool from "./components/solutions/Preschool";
import University from "./components/solutions/University";
import Vocational from "./components/solutions/Vocational";

import WhatsappChat from "./components/WhatsappChat";
import ScrollToTop from "./components/ScrollToTop";
import Footer from "./components/Footer";

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Duration of animations in milliseconds
    });
  }, []);
  return (
    <div className="App">
    <Router>
      <div>
        <Navbar />
        <div className="content-body">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/Home" element={<Home />} />
            <Route path="/About" element={<About />} />
            <Route path="/Contact" element={<Contact />} />
            <Route path="/Demo" element={<Demo />} />
            <Route path="/Pricing" element={<Pricing />} />
            <Route path="/Contact" element={<Contact />} />
            <Route path="/Trail" element={<Trail />} />
            <Route path="/Demo" element={<Demo />} />
            <Route path="/Login" element={<Login />} />

            <Route path="/Features" element={<Features />} />

            <Route path="/Student" element={<Student />} />
            <Route path="/Faculty" element={<Faculty />} />
            <Route path="/Course" element={<Course />} />
            <Route path="/Financial" element={<Financial />} />
            <Route path="/Admission" element={<Admission />} />
            <Route path="/Exams" element={<Exams />} />
            <Route path="/LMS" element={<LMS />} />
            <Route path="/Gradebook" element={<Gradebook />} />
            <Route path="/Attendance" element={<Attendance />} />
            <Route path="/Assignments" element={<Assignments />} />
            <Route path="/ParentLogin" element={<ParentLogin />} />


            <Route path="/Solutions" element={<Solutions />} />

            <Route path="/College" element={<College />} />
            <Route path="/Highschool" element={<Highschool />} />
            <Route path="/K12Schools" element={<K12Schools />} />
            <Route path="/Preschool" element={<Preschool />} />
            <Route path="/University" element={<University />} />
            <Route path="/Vocational" element={<Vocational />} />

            <Route path="*" element={<NoPage />} />
          </Routes>
        </div>
        <WhatsappChat />
        <ScrollToTop />
        <Footer />
      </div>
    </Router>
    </div>
  );
}

export default App;
