import React from "react";
import Card from "./Card";
import MainHeading from "./MainHeading";
import {Col, Container, Row } from "react-bootstrap";

const cardsData = [
  {
    title: "University Management",
    alt: "Jadeed Education Management University Management",
    sicon: "university",
  },
  {
    title: "Highschool Management",
    alt: "Jadeed Education Management University Management",
    sicon: "highschool",
  },
  {
    title: "College Management",
    alt: "Jadeed Education Management College Management",
    sicon: "college",
  },
  {
    title: "Preschool Institute",
    alt: "Jadeed Education Management Pre School Management",
    sicon: "preschool",
  },
  {
    title: "K-12 Schools",
    alt: "Jadeed Education Management K 12 Schools",
    sicon: "k12Schools",
  },
  {
    title: "Vocational Schools",
    alt: "Jadeed Education Management Vocational School Management",
    sicon: "vocational",
  },
  // Add more cards as needed
];

export default function Solutions() {
  return (
    <section>
      <Container>
        <Row className="align-items-center justify-content-center text-center">
          <Col md={10} lg={9} xl={9} className="text-center">
            <MainHeading
              slogan="Solutions"
              title="Tailored Solutions for Every Educational Institution"
              description="We provide tailored solutions for educational institutions, meeting  unique needs with precision. Our customized approaches enhance learning  experiences and streamline administration."
            />
          </Col>
        </Row>
        <Row xs={2} md={3} lg={3} className="justify-content-center text-center">
              {cardsData.map((card, index) => (
                <Col key={index} className="mb-3">
                  <Card sicon={card.sicon} name={card.name} title={card.title} alt={card.alt}/>
                </Col>
              ))}
            </Row>
      </Container>
    </section>
  );
}
