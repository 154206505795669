import React from "react";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import Card from "./Card";
import headerImage from "../images/laptop.svg";
const cardData = [
 {
    className: "frontDesk",
    title: "Front Desk",
    name: "Front Desk",
    alt: "Multi-branch management",
    icon: "frontdesk",
  },
  {
    className: "multiBranches",
    title: "Multi Branches",
    name: "Multi Branches",
    alt: "Cloud-based data storage",
    icon: "multibranches",
  },
  {
    className: "dashboard",
    name: "Dashboard",
    title: "Dashboard",
    alt: "Mobile Application",
    icon: "dashboard",
  },
];
export default function Header() {
  return (
    <div className="App-header">
      <Container>
        <Row xs={1} md={2} className="align-items-center justify-content-center text-center mb-3" data-aos="fade-down" >
          <Col>
            <h2>let's manage your institute with our</h2>
            <h1>Education Management System</h1>
            <div className="buttons-group">
              <Button variant="warning" size="lg">
                Get Started Now
              </Button>{" "}
              <Button variant="outline-light" size="lg">
                Try It For Free
              </Button>
            </div>
          </Col>
        </Row>
        <Row className="align-items-center justify-content-center mt-5"data-aos="fade-down">
          <Col xs={12} md={10} lg={11} className="laptop">
          <Image src={headerImage} alt="Jadeed Education Management System" fluid />
          <div className="cards">
            {cardData.map((card, index) => (
              <div key={index} className={`${card.className} position-absolute p-3`}>
                <Card name={card.name} title={card.title} svgicone={card.icon} alt={card.alt} />
              </div>
            ))}
          </div> 
          </Col>
         
        </Row>
        
      </Container>
    </div>
  );
}
