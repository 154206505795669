import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import MainHeading from "./MainHeading";
import WhyChoose from "./WhyChoose";
export default function WhyJEMS() {
  return (
    <section>
      <div className="whyJEMS">
      <Container>
        <Row sm={1} md={2} className="align-items-center justify-content-end">
          <Col>
            <MainHeading
              slogan="Select"
              title="Why Choose Jadeed EMS?"
              description="JEMS offers a user-friendly platform for managing education with tools for attendance, grades, and communication. Its intuitive interface keeps educators, students, and parents connected. Choose Jadeed EMS for efficient educational administration."
            />
            <WhyChoose/>
          </Col>
          
        </Row>
      </Container>
      </div>
    </section>
  );
}
