import React from "react";
import Header from "./PageHeader";
import { Card, CardBody, Col, Container, Row } from "react-bootstrap";
import ContactForm from "./ContactForm";
import MainHeading from "./MainHeading";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

export default function Contact() {
  return (
    <>
      <Header
        title="Contact Informations"
        caption="Stay Connected: Easily access contact information for support, inquiries, and collaboration within our education management system."
      />
      <section className="contactus">
        <Container>
          <Row>
            <Col xs={1} md={4}>
              <Card className="mb-3">
                <CardBody>
                  <Row>
                    <Col xs={1} md={3}>
                      <div className="card-icon"><FontAwesomeIcon icon={faPhone} /></div>
                    </Col>
                    <Col xs={1} md={9}>
                      <h6>Phone</h6>
                      <a href="tel:+923008639171" >+92 300 8639171</a>, <a href="https://wa.me/923260667666" target="_blank">+92 32 60667666</a>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card className="mb-3">
                <CardBody>
                  <Row>
                    <Col xs={1} md={3}>
                      <div className="card-icon"><FontAwesomeIcon icon={faEnvelope} /></div>
                    </Col>
                    <Col xs={1} md={9}>
                      <h6>Email</h6>
                      <a href="mailto:cotact@jadeedems.com">cotact@jadeedems.com</a>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card className="mb-3">
                <CardBody>
                  <Row>
                    <Col xs={1} md={3}>
                      <div className="card-icon"><FontAwesomeIcon icon={faMapMarkerAlt} /></div>
                    </Col>
                    <Col xs={1} md={9}>
                      <h6>Lahore Office</h6>
                      <p>12-1, block A2 Opp. Cine Star ,Township, Lahore</p>
                      <h6>Multan Office</h6>
                      <p>327-F, Shah Ruken-E-Alam Colony, Multan</p>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col xs={1} md={8}>
              <Card>
                <CardBody>
                <ContactForm />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container>
          <Row className="text-center justify-content-center">
            <Col md={10}>
              <MainHeading
                slogan="GPS"
                title="Find Us On Google Map"
                description="Finding us is easy—just get directions on Google Maps today and visit us for all your needs!"
              />
            </Col>
          </Row>
          <Row sm={1} md={2} lg={2} xl={2} className="justify-content-center">
            <Col>
              <h4>Lahore Office</h4>
              <div className="map-iframe">
                <iframe
                  title="Lahore Office JadeedEMS"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3485.935231425263!2d74.26897251516052!3d31.549567681227275!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3919046e74f453d3%3A0x439ff7cba1c80bb0!2s12-1%2C%20Block%20A2%2C%20Opp.%20Cine%20Star%2C%20Township%2C%20Lahore!5e0!3m2!1sen!2s!4v1635723802671!5m2!1sen!2s"
                  width="100%"
                  height="250"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="eager"
                ></iframe>
              </div>
            </Col>
            <Col>
              <h4>Multan Office</h4>
              <div className="map-iframe">
                <iframe
                  title="Multan Office JadeedEMS"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3404.9904431213315!2d71.5368793154935!3d30.191389391520874!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39224b4c8fbd3815%3A0x1a044a3d5eb3c31!2s327-F%2C%20Shah%20Ruken-E-Alam%20Colony%2C%20Multan%2C%2060000!5e0!3m2!1sen!2s!4v1635746403723!5m2!1sen!2s"
                  width="100%"
                  height="250"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="eager"
                ></iframe>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
