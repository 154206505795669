import React, { useState } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
    contactNo: "",
    instituteName: "",
    city: "",
  });

  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.name) errors.name = "Name is required";
    if (!formData.email) errors.email = "Email is required";
    else if (!/\S+@\S+\.\S+/.test(formData.email))
      errors.email = "Email is invalid";
    if (!formData.subject) errors.subject = "Subject is required";
    if (!formData.message) errors.message = "Message is required";
    if (!formData.contactNo) errors.contactNo = "Contact number is required";
    if (!formData.instituteName)
      errors.instituteName = "Institute name is required";
    if (!formData.city) errors.city = "City is required";
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsSubmitting(true);

    try {
      // Simulate form submission (e.g., send to an API endpoint)
      // Replace with your API call
      console.log("Form data submitted:", formData);
      setFormData({
        name: "",
        email: "",
        subject: "",
        message: "",
        contactNo: "",
        instituteName: "",
        city: "",
      });
      alert("Your message has been sent successfully!");
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit} className="contactForm">
      <Row md={2}>
        <Col>
          <Form.Group controlId="formName">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              isInvalid={!!formErrors.name}
            />
            <Form.Control.Feedback type="invalid">
              {formErrors.name}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="formInstituteName">
            <Form.Label>Institute Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your institute name"
              name="instituteName"
              value={formData.instituteName}
              onChange={handleChange}
              isInvalid={!!formErrors.instituteName}
            />
            <Form.Control.Feedback type="invalid">
              {formErrors.instituteName}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      
      <Row md={2}>
        <Col>
          <Form.Group controlId="formEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter your email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              isInvalid={!!formErrors.email}
            />
            <Form.Control.Feedback type="invalid">
              {formErrors.email}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="formContactNo">
            <Form.Label>Contact Number</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your contact number"
              name="contactNo"
              value={formData.contactNo}
              onChange={handleChange}
              isInvalid={!!formErrors.contactNo}
            />
            <Form.Control.Feedback type="invalid">
              {formErrors.contactNo}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row md={1}>
        <Col><Form.Group controlId="formCity">
        <Form.Label>City</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter your city"
          name="city"
          value={formData.city}
          onChange={handleChange}
          isInvalid={!!formErrors.city}
        />
        <Form.Control.Feedback type="invalid">
          {formErrors.city}
        </Form.Control.Feedback>
      </Form.Group></Col>
      </Row>
      <Row md={1}>
        <Col><Form.Group controlId="formSubject">
        <Form.Label>Subject</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter the subject"
          name="subject"
          value={formData.subject}
          onChange={handleChange}
          isInvalid={!!formErrors.subject}
        />
        <Form.Control.Feedback type="invalid">
          {formErrors.subject}
        </Form.Control.Feedback>
      </Form.Group></Col>
      </Row>
      <Row md={1}>
        <Col><Form.Group controlId="formMessage">
        <Form.Label>Message</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          placeholder="Enter your message"
          name="message"
          value={formData.message}
          onChange={handleChange}
          isInvalid={!!formErrors.message}
        />
        <Form.Control.Feedback type="invalid">
          {formErrors.message}
        </Form.Control.Feedback>
      </Form.Group></Col>
      </Row>

      <Button variant="warning" type="submit" disabled={isSubmitting}>
        {isSubmitting ? "Sending..." : "Send Message"}
      </Button>
    </Form>
  );
};

export default ContactForm;
