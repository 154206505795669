import React from 'react'
import Header from "./PageHeader";
import headerbg from "../bg.jpg";
import { Col, Container, Row } from 'react-bootstrap';
import MainHeading from './MainHeading';
export default function Demo() {
  return (
    <>
    <Header backgroundImage={headerbg} title="Demo " caption="Explore, Learn, and Manage with Ease – The Ultimate Education Management System Demo!" />
    <section>
      <Container>
        <Row>
          <Col>
            <MainHeading title="Demo"/>
          </Col>
        </Row>
      </Container>
    </section>
    </>
    
  )
}
