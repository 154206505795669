import React from "react";
import Header from "./Header";
import TrustedBy from "./TrustedBy";
import HomeFeatures from "./HomeFeatures";
import WhyJEMS from "./WhyJEMS";
import HomeSolutions from "./HomeSolutions";
import QuickAccess from "./QuickAccess";
import HomeAbout from "./HomeAbout";
import ProfessionalsRecommending from "./ProfessionalsRecommending";
export default function Home() {
  return (
    <>
      <Header />
      <TrustedBy />
      <HomeAbout/>
      <HomeSolutions />
      <HomeFeatures />
      <ProfessionalsRecommending/>
      <WhyJEMS />
    </>
  );
}
