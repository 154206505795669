import React from "react";
import { Container } from "react-bootstrap";

export default function PageHeader({ title, caption }) {
  return (
    <>
      <section className="pageHeader text-center">
        <Container>
          {title && <h1>{title}</h1>}
          {caption && <p>{caption}</p>}
        </Container>
      </section>
    </>
  );
}
