import React from 'react'
import Header from '../PageHeader'
import {Image, Button, Col, Container, Row } from 'react-bootstrap'
import MainHeading from '../MainHeading'
import student from"../../images/student.jpg";
import Card from "../Card";
import ProfessionalsRecommending from '../ProfessionalsRecommending';
const Features = [
  {
    title: "Organizational Setup",
    sicon: "university",
    description:"Establish an efficient system for your college operations to boost productivity, reduce stress, and achieve your academic goals.",
  },
  {
    title: "Campaigns & Inquiries",
    sicon: "university",
    description:"It helps colleges attract and retain students, boost their reputation, and enhance the overall student experience.",
  },
  {
    title: "Admission",
    sicon: "university",
    description:"Streamlined registration for quick and easy enrollment on our platform.",
  },
  {
    title: "Student Information System",
    sicon: "university",
    description:"It offers a centralized database for administrators, faculty, and staff to manage student records, track academic progress, and support student success.",
  },
  {
    title: "Fees Management",
    sicon: "university",
    description:"Centralized fee management system to handle student accounts, track payments, and issue receipts.",
  },
  {
    title: "Program & Course",
    sicon: "university",
    description:"Efficient and user-friendly course management system for easy handling of courses, subjects, and sessions with just a few clicks.",
  },
  {
    title: "Assignment",
    sicon: "university",
    description:"Assign and manage tasks for individual students, groups, or entire batches with just a few clicks.",
  },
  {
    title: "Time-Table",
    sicon: "university",
    description:"One-click, single-screen scheduling solution for planning classes, notifying students, and sharing resources with faculty.",
  },
  {
    title: "Student Attendance",
    sicon: "university",
    description:"Track and monitor student attendance to ensure regular class participation and compliance with course requirements.",
  },
  {
    title: "Examination & Marksheet ",
    sicon: "university",
    description:"Comprehensive platform for scheduling various exams, including CCE, CPA, and more.",
  },
  {
    title: "Certificates & Document",
    sicon: "university",
    description:"Academic and corporate users can efficiently store all organizational documents and transition to a paperless operation.",
  },
  {
    title: "Faculty Management",
    sicon: "university",
    description:"Effortlessly manage faculty information, including skills, degrees, courses, and employment history.",
  },
  {
    title: "Analytics & Report",
    sicon: "university",
    description:"Administrators and faculty can make data-driven decisions to boost student success, enhance operational efficiency, and improve overall college quality.",
  },
  {
    title: "Messaging & Notifications",
    sicon: "university",
    description:"Keep students, faculty, and staff updated on important events, deadlines, and relevant information via the mobile app.",
  },
  
]
export default function University() {
  return (
    <>
    <Header title="College Management System" caption="Streamline campus operations with our all-in-one College Management Solutions for efficient student management."/>
    <section>
        <Container>
            <Row xs={1} md={2} className='align-items-center'>
                <Col>
                    <MainHeading title="All-in-One College Management System" description="Jadeed EMS Educational ERP software simplifies college administration with comprehensive features for analyzing student performance, budgeting, attendance, and more."/>
                    <Button variant="warning">Try  for free</Button>{' '}<Button variant="secondary">Get a quote</Button>{' '}
                </Col>
                <Col className='text-center'><Image src={student} alt='' fluid /></Col>
            </Row>
            <Row className='text-center justify-content-center'>
          <Col md={10}>
            <MainHeading slogan="Benefits" title="Upgrade College Operations with Our ERP Solutions" description="Revolutionize your college with our Educational ERP Solutions. Streamline administration, analyze performance, and manage budgeting and attendance effortlessly."/>
          </Col>
        </Row>
        <Row sm={1} md={3} lg={4} xl={4} className="align-items-center justify-content-center text-center" >
            {Features.map((card, index) => (
              <Col key={index} className="mb-3">
                <Card sicon={card.sicon} title={card.title} alt={card.title} description={card.description}/>
              </Col>
            ))}
          </Row>
        </Container>
    </section>
    <ProfessionalsRecommending/>
    
    </>
  )
}
