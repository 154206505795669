import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import MainHeading from './MainHeading'

export default function ProfessionalsRecommending() {
  return (
    <>
        <section>
            <Container>
                <div className='professionalsRecommending'>
                <Row xs={1} md={2} lg={2} className="align-items-center">
                    <Col>
                        <MainHeading title="10,000+ professionals are recommending EMS Products"/>
                    </Col>
                    <Col className='text-center'>
                        <Button variant="warning" size="lg">
                            Get Started Now
                        </Button>{" "}
                        <Button variant="outline-light" size="lg">
                            Try It For Free
                        </Button>
                    </Col>
                </Row>
                </div>
            </Container>
        </section>
    </>
  )
}
