import React from 'react'
import Header from '../PageHeader'
import {Image, Button, Col, Container, Row } from 'react-bootstrap'
import MainHeading from '../MainHeading'
import student from"../../images/student.jpg";
import Card from "../Card";
import ProfessionalsRecommending from '../ProfessionalsRecommending';
const Features = [
  {
    title: "Student Management",
    sicon: "university",
    description:"The ERP system should efficiently manage student data, such as admissions, registration, and grades.",
  },
  {
    title: "Faculty Management",
    sicon: "university",
    description:"Manage faculty information, including schedules, workload, research, and evaluations.",
  },
  {
    title: "Course Management ",
    sicon: "university",
    description:"The system must manage course data, such as catalogs, schedules, syllabi, and assignments.",
  },
  {
    title: "Financial Management",
    sicon: "university",
    description:"Handle financial information, including budgeting, accounting, billing, and payments.",
  },
  {
    title: "Security and Privacy",
    sicon: "university",
    description:"Protect the security and privacy of sensitive information like student records and financial data.",
  },
  {
    title: "Admission Management",
    sicon: "university",
    description:"Manage the admission process, including application processing, document verification, and decisions.",
  },
  {
    title: "Exam Management",
    sicon: "university",
    description:"Oversee the examination process, including scheduling, registration, grading, and reporting.",
  },
  {
    title: "Event Management",
    sicon: "university",
    description:"The platform must manage academic events, including booking, enrollment, and attendance.",
  },
  {
    title: "Gradebook",
    sicon: "university",
    description:"JadeedEMS's class management and gradebook streamline grade recording and class management.",
  },
  {
    title: "Transcript ",
    sicon: "university",
    description:"Obtain secure and tamper-proof results, transcripts, and official marksheets with JadeedEMS.",
  },
  {
    title: "Inventory Management",
    sicon: "university",
    description:"The system should be capable of managing inventory, including office supplies, equipment, & materials.",
  },
  {
    title: "Human Resources Management ",
    sicon: "university",
    description:"Handle human resources tasks, including employee records, payroll, benefits, and performance evaluations.",
  },
  {
    title: "Timetable Management",
    sicon: "university",
    description:"The system should manage the timetable, including scheduling, room assignments, and instructor availability.",
  },
  {
    title: "Data Analytics and Visualization",
    sicon: "university",
    description:"Use data analytics and visualization tools, like dashboards, for decision-making and monitoring.",
  },
  
]
export default function University() {
  return (
    <>
    <Header title="University Management System" caption="Empower Your Campus with a University Management System – Simplify Operations, Enhance Learning, and Foster Growth!"/>
    <section>
        <Container>
            <Row xs={1} md={2} className='align-items-center'>
                <Col>
                    <MainHeading title="University Management System" description="Consolidate and manage all student-related information in one place, from personal details and attendance records to disciplinary actions, achievements, and more. Discover the advantages of our Student Information System (SIS) today."/>
                    <Button variant="warning">Try  for free</Button>{' '}<Button variant="secondary">Get a quote</Button>{' '}
                </Col>
                <Col className='text-center'><Image src={student} alt='' fluid /></Col>
            </Row>
            <Row className='text-center justify-content-center'>
          <Col md={10}>
            <MainHeading slogan="Benefits" title="Key Features of an Educational ERP for Universities" description="Unlock the future of learning with an Educational ERP for Universities: Streamline administration, enhance student engagement, and boost academic performance with integrated tools and insights."/>
          </Col>
        </Row>
        <Row sm={1} md={3} lg={4} xl={4} className="align-items-center justify-content-center text-center" >
            {Features.map((card, index) => (
              <Col key={index} className="mb-3">
                <Card sicon={card.sicon} title={card.title} alt={card.title} description={card.description}/>
              </Col>
            ))}
          </Row>
        </Container>
    </section>
    <ProfessionalsRecommending/>
    </>
  )
}
