import React from 'react'
import Header from '../PageHeader'
import {Image, Button, Col, Container, Row } from 'react-bootstrap'
import MainHeading from '../MainHeading'
import Card from "../Card";
import financial from"../../images/financial.jpg";
import ProfessionalsRecommending from '../ProfessionalsRecommending';
const FinancialFeatures = [
  {
    title: "Flexible Fees Structure",
    sicon: "university",
    description:"Manage and track student fees and tuition, including fee structures, payment plans, and late fees.",
  },
  {
    title: "Student Accounting",
    sicon: "university",
    description:"Allow institutions to track student finances, including tuition payments, scholarships, and transactions.",
  },
  {
    title: "Fees Collection",
    sicon: "university",
    description:"Educational institutions, like universities, collect fees including annual, examination, and tuition.",
  },
  {
    title: "Accounts Payable",
    sicon: "university",
    description:"Managing payments to vendors involves invoice processing, payment approvals, and vendor oversight.",
  },
  {
    title: "Bank Management",
    sicon: "university",
    description:"Educational institutions manage bank accounts by tracking deposits, withdrawals, and transfers.",
  },
  {
    title: "Fixed Asset Management",
    sicon: "university",
    description:"Monitor and manage the institution’s assets to assess depreciation and plan repairs or replacements.",
  },
  {
    title: "User-Friendly Interface",
    sicon: "university",
    description:"An intuitive interface helps staff navigate and use the software easily, minimizing the learning curve.",
  },
  {
    title: "Automated Reminders",
    sicon: "university",
    description:"Check the fee report and promptly notify students or parents of pending fees through the mobile app.",
  },
  {
    title: "Expense Tracking",
    sicon: "university",
    description:"Track and categorize expenses to identify cost-saving opportunities and manage operational costs.",
  },
  {
    title: "Financial Reporting",
    sicon: "university",
    description:"Generate financial reports and statements to assess financial health, aid decision-making, and ensure compliance.",
  },
  {
    title: "Cloud-Based Accessibility",
    sicon: "university",
    description:"Authorized personnel can securely access financial data remotely, enabling efficient management and review.",
  },
  {
    title: "Payroll and HR Management",
    sicon: "university",
    description:"Automated payroll and HR functions streamline finances, ensure timely salaries, and boost efficiency.",
  },
  {
    title: "Faculty",
    sicon: "university",
    description:"Maintain",
  },
  {
    title: "Faculty",
    sicon: "university",
    description:"Maintain",
  },
  {
    title: "Faculty",
    sicon: "university",
    description:"Maintain",
  },
]
export default function Financial() {
  return (
    <>
    <Header title="Financial Management System" caption="Empowering Your Financial Future with Streamlined, Efficient Management"/>
    <section>
        <Container>
            <Row xs={1} md={2} className='align-items-center'>
                <Col>
                    <MainHeading title="Empowering Education with Seamless Financial Management" description="Revolutionize your institution's financial operations with OpenEduCat—experience smarter financial management, greater transparency, and sustainable success."/>
                    <Button variant="warning">Try  for free</Button>{' '}<Button variant="secondary">Get a quote</Button>{' '}
                </Col>
                <Col className='text-center'><Image src={financial} alt='' fluid /></Col>
            </Row>
        </Container>
    </section>
    <section>
        <Container>
          <Row className='text-center'>
            <Col>
            <MainHeading slogan="Benefits" title="Unlock Financial Clarity and Efficiency for Your Institution" description="Adopting a Financial Management System in an educational institution can provide a range of advantages. These systems are built to optimize financial processes, boost accuracy, and increase overall efficiency."/>
            </Col>
          </Row>
          <Row sm={1} md={3} lg={4} xl={4} className="align-items-center justify-content-center text-center" >
            {FinancialFeatures.map((card, index) => (
              <Col key={index} className="mb-3">
                <Card sicon={card.sicon} title={card.title} alt={card.title} description={card.description}/>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    <ProfessionalsRecommending/>
    </>
  )
}
