import React from "react";
import Header from "./PageHeader";
import { Image, Button, Col, Container, Row } from "react-bootstrap";
import MainHeading from "./MainHeading";
import ProfessionalsRecommending from "./ProfessionalsRecommending";
import ceoimage from "../images/CEOJEMS.svg";
import sales from "../images/sales.png";
import SvgIcon from "./SvgIcon";
import offer from "../images/offer.jpg";
import mission from "../images/mission.jpg";
import vision from "../images/vision.jpg";
import whychoose from "../images/whychoose.jpg";
const sectionsData = [
  {
    title: "Our Mission",
    description:
      "Our mission is to empower educational institutions with cutting-edge technology that simplifies complex processes, enhances the learning environment, and promotes excellence in education. We strive to provide a system that bridges the gap between teachers, students, and administration, ensuring seamless interaction and effective management.",
      image: mission,
  },
  {
    title: "Our Vision",
    description:
      "We envision a future where educational institutions of all sizes can leverage technology to achieve operational excellence, improve student outcomes, and foster collaboration among educators, students, and parents. At Jadeed, we believe that education is the cornerstone of a thriving society, and we are committed to making education more accessible, organized, and impactful through our comprehensive management solution.",
    image: vision,
  },
  {
    title: "What We Offer",
    description:
      "Our platform offers a wide range of features tailored to meet the specific needs of the education sector:",
    items: [
      "Student Management: Easily track student progress, attendance, grades, and performance.",
      "Course Management: Organize courses, manage curriculum, and schedule classes with ease.",
      "Teacher and Staff Management: Simplify staff assignments, track professional development, and streamline payroll processes.",
      "Parent-Teacher Communication: Enhance communication between parents, teachers, and students through secure portals and real-time updates.",
      "Administrative Tools: Automate admissions, fee collection, and reporting, enabling institutions to focus more on education and less on paperwork.",
      "Data Security: Our platform is built with advanced security measures to protect sensitive information and ensure compliance with educational regulations.",
    ],
    image: offer,
  },
  {
    title: "Why Choose Jadeed?",
    description:
      "Our platform offers a wide range of features tailored to meet the specific needs of the education sector:",
    items: [
      "Tailored for Education: We understand the unique challenges faced by educational institutions, and our platform is designed with these in mind.",
      "User-Friendly Interface: Jadeed is easy to use for all stakeholders, from administrators to students and parents.",
      "Scalable Solutions: Whether you're a small school or a large university, our system can grow with your needs.",
      "Dedicated Support: Our team is committed to providing top-tier support to help you get the most out of our system.",
    ],
    image: whychoose,
  },

  // Add more sections as needed
];

export default function About() {
  return (
    <>
      <div className="solutions about text-start">
        <Header
          title="About"
          caption="Empower Your Campus with a University Management System – Simplify Operations, Enhance Learning, and Foster Growth!"
        />
        <section>
          <Container>
            <Row xs={2} md={2} lg={2} xl={2} className="align-items-center justify-content-center">
              <Col className="about-image mb-3" data-aos="fade-right">
                <Image src={ceoimage} alt="" fluid />
                <div className="sales">
                  <h6>sales-Comparison</h6>
                  <Image src={sales} alt="" fluid />
                </div>
                <div className="faculty">
                  <Row className="align-items-center">
                    <Col xs={1} md={4}>
                      <SvgIcon icon="faculty" />
                    </Col>
                    <Col xs={1} md={8}>
                      <h6>85٪</h6>
                      <p>Elevate Faculty Efficiency</p>
                    </Col>
                  </Row>
                </div>
                <div className="fee">
                  <Row className="align-items-center">
                    <Col xs={1} md={4}>
                      <SvgIcon icon="fee" />
                    </Col>
                    <Col xs={1} md={8}>
                      <h6>120٪</h6>
                      <p>Enhance Fee Collection</p>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col className="about-content" data-aos="fade-left">
                <MainHeading
                  slogan="About JEMS"
                  title="Welcome to Jadeed Education Management System"
                  description="At Jadeed Education Management System, we are dedicated to revolutionizing the way educational institutions operate, making the learning experience more streamlined, efficient, and impactful. Our innovative platform is designed to cater to the needs of schools, colleges, universities, and educational organizations, enabling them to manage all aspects of their administration, academics, and communication through a single, user-friendly interface."
                />
              </Col>
            </Row>
          </Container>
        </section>
        {sectionsData.map((section, index) => (
          <section key={index}>
            <Container>
              <Row xs={1} md={2} className={`align-items-center text-start`}>
                {/* Conditionally swap the order of columns based on index */}
                {index % 2 === 0 ? (
                  <>
                    <Col>
                      <MainHeading
                        title={section.title}
                        description={section.description}
                      />
                      {section.items && section.items.length > 0 && (
                        <ul>
                          {section.items.map((item, itemIndex) => (
                            <li key={itemIndex}>{item}</li>
                          ))}
                        </ul>
                      )}
                    </Col>
                    <Col className="text-center">
                      <Image src={section.image} alt={section.title} fluid />
                    </Col>
                  </>
                ) : (
                  <>
                    <Col className="text-center">
                    <Image src={section.image} alt={section.title} fluid />
                    </Col>
                    <Col>
                      <MainHeading
                        title={section.title}
                        description={section.description}
                      />
                      {section.items && section.items.length > 0 && (
                        <ul>
                          {section.items.map((item, itemIndex) => (
                            <li key={itemIndex}>{item}</li>
                          ))}
                        </ul>
                      )}
                    </Col>
                  </>
                )}
              </Row>
            </Container>
          </section>
        ))}
      </div>
      <ProfessionalsRecommending />
    </>
  );
}
