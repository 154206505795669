import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
export default function WhatsappChat() {
  const handleClick = () => {
    window.open('https://wa.me/923260667666', '_blank');
  };
  return (
    <button className="whatsapp-button" onClick={handleClick}>
      <FontAwesomeIcon icon={faWhatsapp} className='icon' /> Chat with us
    </button>
  )
}
