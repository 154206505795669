import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faEnvelope,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { NavLink } from "react-router-dom";
import { ReactComponent as Logo } from "../logowhite.svg";
import Socialmedia from "./Socialmedia";
import QuickAccess from "./QuickAccess";

export default function Footer() {
  const currentYear = new Date().getFullYear();
  const services = [
    { link: "/About", title: "Implementation" },
    { link: "/Consulting", title: "Consulting" },
    { link: "/Integration", title: "Integration" },
    { link: "/Training", title: "Training" },
    { link: "/Support", title: "Support" },
  ];
  const solutions = [
    { link: "/University", title: "University Management" },
    { link: "/College", title: "College Management" },
    { link: "/Highschool", title: "High School Management" },
    { link: "/K12Schools", title: "K-12 Schools" },
    { link: "/Preeschool", title: "Pree School Institutes" },
    { link: "/Vocational", title: "Vocational Schools" },
  ];
  const resources = [
    { link: "/", title: "Clients" },
    { link: "/", title: "Mobile App" },
    { link: "/", title: "Videos" },
    { link: "/", title: "Downloads" },
    { link: "/", title: "Financial System" },
  ];

  return (
    <>
    <QuickAccess/>
      <footer className="footer">
        <Container>
          <Row>
            <Col xs={12} md={3}>
              <Logo
                className="footer-logo mb-2"
                alt="Jadeed Education Management System"
              />
              <p>
                all-in-one platform designed to optimize and modernize
                educational administration. This innovative system provides a
                suite of tools and features that streamline the management of
                student information, faculty records, attendance, grading, and
                financial operations.
              </p>
              <Socialmedia />
            </Col>
            <Col xs={12} md={2}>
              <h4>Services</h4>
              <ul>
                {services.map((item, index) => (
                  <li key={index}>
                    <NavLink to={item.link}>{item.title}</NavLink>
                  </li>
                ))}
              </ul>
            </Col>
            <Col xs={12} md={2}>
              <h4>Solutions</h4>
              <ul>
                {solutions.map((item, index) => (
                  <li key={index}>
                    <NavLink to={item.link}>{item.title}</NavLink>
                  </li>
                ))}
              </ul>
            </Col>
            <Col xs={12} md={2}>
              <h4>Resources</h4>
              <ul>
                {resources.map((item, index) => (
                  <li key={index}>
                    <NavLink to={item.link}>{item.title}</NavLink>
                  </li>
                ))}
              </ul>
            </Col>
            <Col xs={12} md={3}>
              <h4>Stay Connected</h4>
              <ul>
                <li>
                  <b>
                    <FontAwesomeIcon icon={faMapMarkerAlt} /> Lahore Office:
                  </b>{" "}
                  <NavLink to="https://maps.app.goo.gl/nV3e84P3w9tuZhwb6">
                    12-1, block A2 Opp. Cine Star ,Township, Lahore
                  </NavLink>
                </li>
                <li>
                  <b>
                    <FontAwesomeIcon icon={faMapMarkerAlt} /> Multan Office:
                  </b>{" "}
                  <NavLink to="https://maps.app.goo.gl/nV3e84P3w9tuZhwb6">
                    327-F, Shah Ruken-E-Alam Colony, Multan, 60000
                  </NavLink>
                </li>
                <li>
                  <b>
                    <FontAwesomeIcon icon={faEnvelope} /> Email
                  </b>{" "}
                  <NavLink to="mailto:cotact@jadeedems.com">
                    cotact@jadeedems.com
                  </NavLink>
                </li>
                <li>
                  <b>
                    <FontAwesomeIcon icon={faPhone} /> Phone
                  </b>{" "}
                  <NavLink to="tel:+923260667666">+92 300 8639171</NavLink>
                </li>
                <li>
                  <b>
                    <FontAwesomeIcon icon={faWhatsapp} /> WhatsApp
                  </b>{" "}
                  <NavLink to="tel:+923260667666">+92 32 60667666</NavLink>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
        </footer>
        <div className="copyright py-3">
<Container>
          <Row>
            <Col>
              Copyright © {currentYear}{" "}
              <b>
                <NavLink to="/">JadeedEMS.com</NavLink>
              </b>
              . <span>All Rights are Reserved</span>{" "}
            </Col>
            <Col className="text-end">
              Developed by:{" "}
              <b>
                <NavLink to="https://softagics.com/">www.softagics.com</NavLink>
              </b>
              .
            </Col>
          </Row>
        </Container>
        </div >
      
    </>
  );
}
