import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram, faLinkedin, faWhatsapp, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { NavLink } from "react-router-dom";

const socialMediaLinks = [
  {
    icon: faFacebook,
    url: "https://www.facebook.com/softagics/",
    isExternal: true,
    label: "Facebook Jadeed Education Management System JEMS jems"
  },
  {
    icon: faInstagram,
    url: "https://www.instagram.com/softagicspak/",
    isExternal: true,
    label: "Instagram Jadeed Education Management System JEMS jems"
  },
  {
    icon: faYoutube,
    url: "/Services",
    isExternal: false,
    label: "YouTube Jadeed Education Management System JEMS jems"
  },
  {
    icon: faTwitter,
    url: "/Services",
    isExternal: false,
    label: "Twitter Jadeed Education Management System JEMS jems"
  },
  {
    icon: faLinkedin,
    url: "https://www.linkedin.com/company/softagicspak/mycompany/",
    isExternal: true,
    label: "LinkedIn Jadeed Education Management System JEMS jems"
  },
  {
    icon: faWhatsapp,
    url: "tel:+923260667666",
    isExternal: false,
    label: "WhatsApp Jadeed Education Management System JEMS jems"
  }
];

export default function Socialmedia() {
  return (
    <ul className="social">
      {socialMediaLinks.map((link, index) => (
        <li key={index}>
          {link.isExternal ? (
            <a 
              href={link.url} 
              target="_blank" 
              rel="noopener noreferrer" 
              aria-label={link.label}
              title={`Visit our ${link.label} page`}
            >
              <FontAwesomeIcon icon={link.icon} />
            </a>
          ) : (
            <NavLink to={link.url} aria-label={link.label} title={`Visit our ${link.label} page`}>
              <FontAwesomeIcon icon={link.icon} />
            </NavLink>
          )}
        </li>
      ))}
    </ul>
  );
}
