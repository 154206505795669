import React from 'react';
import { ReactComponent as college } from '../images/icons/college.svg';
import { ReactComponent as highschool } from '../images/icons/highschool.svg';
import { ReactComponent as k12Schools } from '../images/icons/k12Schools.svg';
import { ReactComponent as preschool } from '../images/icons/preschool.svg';
import { ReactComponent as school } from '../images/icons/school.svg';
import { ReactComponent as university } from '../images/icons/university.svg';
import { ReactComponent as vocational } from '../images/icons/vocational.svg';
import { ReactComponent as dashboard } from '../images/icons/dashboard.svg';
import { ReactComponent as frontdesk } from '../images/icons/frontdesk.svg';
import { ReactComponent as multibranches } from '../images/icons/multi-branches.svg';
import { ReactComponent as fee } from '../images/icons/fee-collection.svg';
import { ReactComponent as faculty } from '../images/icons/faculty.svg';
import { ReactComponent as nextarrow } from '../images/icons/next-arrow.svg';

const icons = {
  college: college,
  highschool: highschool,
  k12Schools: k12Schools,
  preschool: preschool,
  school: school,
  university: university,
  vocational: vocational,
  dashboard: dashboard,
  frontdesk: frontdesk,
  multibranches: multibranches,
  fee: fee,
  faculty: faculty,
  nextarrow: nextarrow,
};

const SvgIcon = ({ icon, ...props }) => {
  const IconComponent = icons[icon];
  return IconComponent ? <IconComponent {...props} /> : null;
};

export default SvgIcon;
