import React from "react";

export default function MainHeading({ title, slogan, description, subtitle }) {
  return (
    <div className="heading">
      {slogan && <p className="heading-slogan">{slogan}</p>}
      {title && <h1 className="heading-title">{title}</h1>}
      {description && <p className="heading-description">{description}</p>}
      {subtitle && <h6 className="heading-subtitle">{subtitle}</h6>}
    </div>
  );
}
