import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import MainHeading from "./MainHeading";

export default function QuickAccess() {
  return (
    <section className="requestTrial">
      <Container>
        <Row lg={2} xl={2} className="align-items-center justify-content-center">
          <Col>
            <MainHeading
              title="Request a FREE Trial"
              description="Please fill-in and submit the request form to get a 60-days FREE trial of this software"
            />
          </Col>
          <Col className="text-end">
            <Button variant="warning"size="lg">Start Free Trail</Button>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
